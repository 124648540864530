import React from "react";
import { media } from "styled-bootstrap-grid";
import styled, { css } from "styled-components";

const SectionVector = ({
  top,
  right,
  bottom,
  left,
  height,
  width,
  d,
  position,
}) => {
  return (
    <Vector
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      position={position}
    >
      <path fillRule="evenodd" clipRule="evenodd" d={d} fill="#E8EAF6" />
    </Vector>
  );
};

const Vector = styled.svg`
  position: ${(p) => (p.position ? p.position : "absolute")};
  pointer-events: none;
  display: none;

  ${media.xl`
    display: block;
  `}

  ${(p) =>
    p.top &&
    css`
      top: ${`${p.top}px`};
    `}
  ${(p) =>
    p.right &&
    css`
      right: ${`${p.right}px`};
    `}
  ${(p) =>
    p.bottom &&
    css`
      bottom: ${`${p.bottom}px`};
    `}
  ${(p) =>
    p.left &&
    css`
      left: ${`${p.left}px`};
    `}
`;

export default SectionVector;
