import styled, { css } from "styled-components";
import { color, compose, space, typography } from "styled-system";
import theme from "../../utils/theme";
import { Container } from "./Grid";

export const Section = styled.section`
  position: relative;

  ${(p) =>
    p.pt
      ? p.pt
      : p.py
      ? p.py
      : p.pb
      ? p.pb
      : css`
          padding-top: 40px;
          padding-bottom: 40px;

          ${theme.media.up("lg")} {
            padding-top: 64px;
            padding-bottom: 64px;
          }
        `}

  ${(p) =>
    p.scaled &&
    css`
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      ${Container} {
        padding-top: 40px;
        padding-bottom: 40px;

        ${theme.media.up("lg")} {
          padding-top: 64px;
          padding-bottom: 64px;
        }
      }
    `}

  ${compose(space, typography, color)};
`;
