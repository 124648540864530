import React from 'react'
import Element from '../UI/Element'
import SectionVector from './SectionVector'

const DefaultVector = ({ children, noBottom, inverse }) => {
  return (
    <>
      <Element display="flex" justifyContent={inverse ? 'end' : 'start'}>
        <SectionVector
          width="140"
          height="100"
          d={
            inverse
              ? 'M140.002 0H0.00195312V38.8886H99.5986V100H140.002V0Z'
              : 'M0 -0.000244141H140V38.8887H40.4028V99.9998H0V-0.000244141Z'
          }
          position="static"
        />
      </Element>
      {children}
      {!noBottom && (
        <Element display="flex" justifyContent={inverse ? 'start' : 'end'}>
          <SectionVector
            width="167"
            height="55"
            d="M0.500001 0L0.5 55L167.5 55L167.5 1.99146e-06L0.500001 0Z"
            position="static"
          />
        </Element>
      )}
    </>
  )
}

export default DefaultVector
