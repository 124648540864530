import React from 'react'
import Seo from '../components/Layout/Seo'
import Site from '../components/Layout/Site'
import { Row, Col, Container } from '../components/UI/Grid'
import { Section } from '../components/UI/Section'
import { Heading } from '../components/UI/Typography'
import styled from 'styled-components'
import theme from '../utils/theme'
import { Paragraph } from '../components/UI/Typography'
import Element from '../components/UI/Element'
import DefaultVector from '../components/Shared/DefaultVector'

const TermsAndConditions = () => {
  return (
    <Site>
      <Seo title="Terms & Conditions" />

      <Section py={{ _: 16, lg: 0 }}>
        <DefaultVector>
          <Container mb={{ _: 0, lg: 16 }}>
            <Row justifyContent="center">
              <Col col={{ lg: 9 }}>
                <Heading size="display-4" textAlign="center" mb={10}>
                  Terms and Conditions
                </Heading>

                <Paragraph mb={2}>
                  Please read these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully
                  before using the Fastech Synergy Philippines Inc's website (the “Service”)
                  operated by Fastech Synergy Philippines Inc. (“us”, “we”, or “our”).
                </Paragraph>

                <Paragraph mb={2}>
                  Your access to and use of the Service is conditioned on your acceptance of and
                  compliance with these Terms. These Terms apply to all visitors, users and others
                  who access or use the Service.
                </Paragraph>

                <Paragraph mb={10}>
                  By accessing or using the Service you agree to be bound by these Terms. If you
                  disagree with any part of the terms, then you may not access the Service.
                </Paragraph>

                <ParagraphTitle>Availability, Errors, and Inaccuracies</ParagraphTitle>

                <Paragraph mb={2}>
                  We are regularly updating our offerings of products and services on the Service.
                  The products or services available on our Service may be outdated at times, or
                  unavailable, and we may experience delays in updating information on the Service
                  and in our advertising on other web sites.
                </Paragraph>

                <Paragraph mb={10}>
                  We cannot and do not guarantee the accuracy or completeness of any information,
                  including product images, specifications, availability, and services at all times.
                  We reserve the right to change or update information and to correct errors,
                  inaccuracies, or omissions at any time without prior notice.
                </Paragraph>

                <ParagraphTitle>Contests and Promotions</ParagraphTitle>

                <Paragraph mb={10}>
                  Any contests or other promotions (collectively, “Promotions”) made available
                  through the Service may be governed by rules that are separate from these Terms.
                  If you participate in any Promotions, please review the applicable rules as well
                  as our Privacy Policy. If the rules for a Promotion conflict with these Terms, the
                  Promotion rules will apply.
                </Paragraph>

                <ParagraphTitle>Intellectual Property</ParagraphTitle>

                <Paragraph mb={10}>
                  The Service and its original content, features and functionality are and will
                  remain the exclusive property of Fastech Synergy Philippines Inc. and its
                  licensors. The Service is protected by copyright, trademark, and other laws of
                  both the Philippines and foreign countries. Our trademarks and trade dress may not
                  be used in connection with any product or service without the prior written
                  consent of Fastech Synergy Philippines Inc.
                </Paragraph>

                <ParagraphTitle>Links To Other Websites</ParagraphTitle>

                <Paragraph mb={2}>
                  Our Service may contain links to third-party web sites or services that are not
                  owned or controlled by Fastech Synergy Philippines Inc.
                </Paragraph>

                <Paragraph mb={2}>
                  Fastech Synergy Philippines Inc. has no control over, and assumes no
                  responsibility for, the content, privacy policies, or practices of any third-party
                  web sites or services. You further acknowledge and agree that Fastech Synergy
                  Philippines Inc. shall not be responsible or liable, directly or indirectly, for
                  any damage or loss caused or alleged to be caused by or in connection with use of
                  or reliance on any such content, goods or services available on or through any
                  such web sites or services.
                </Paragraph>

                <Paragraph mb={10}>
                  We strongly advise you to read the terms and conditions and privacy policies of
                  any third-party web sites or services that you visit.
                </Paragraph>

                <ParagraphTitle>Termination</ParagraphTitle>

                <Paragraph mb={2}>
                  We may terminate or suspend access to our Service immediately, without prior
                  notice or liability, for any reason whatsoever, including without limitation if
                  you breach the Terms.
                </Paragraph>

                <Paragraph mb={10}>
                  All provisions of the Terms which by their nature should survive termination shall
                  survive termination, including, without limitation, ownership provisions, warranty
                  disclaimers, indemnity, and limitations of liability.
                </Paragraph>

                <ParagraphTitle>Indemnification</ParagraphTitle>

                <Paragraph mb={10}>
                  You agree to defend, indemnify, and hold harmless Fastech Synergy Philippines Inc.
                  and its licensee and licensors, and their employees, contractors, agents,
                  officers, and directors, from and against any and all claims, damages,
                  obligations, losses, liabilities, costs or debt, and expenses (including but not
                  limited to attorney’s fees), resulting from or arising out of a) your use and
                  access of the Service, by you, or b) a breach of these Terms.
                </Paragraph>

                <ParagraphTitle>Limitation Of Liability</ParagraphTitle>

                <Paragraph mb={10}>
                  In no event shall Fastech Synergy Philippines Inc., nor its directors, employees,
                  partners, agents, suppliers, or affiliates, be liable for any indirect,
                  incidental, special, consequential or punitive damages, including without
                  limitation, loss of profits, data, use, goodwill, or other intangible losses,
                  resulting from (i) your access to or use of or inability to access or use the
                  Service; (ii) any conduct or content of any third party on the Service; (iii) any
                  content obtained from the Service; and (iv) unauthorized access, use or alteration
                  of your transmissions or content, whether based on warranty, contract, tort
                  (including negligence) or any other legal theory, whether or not we have been
                  informed of the possibility of such damage, and even if a remedy set forth herein
                  is found to have failed of its essential purpose.
                </Paragraph>

                <ParagraphTitle>Exclusions</ParagraphTitle>

                <Paragraph mb={10}>
                  Some jurisdictions do not allow the exclusion of certain warranties or the
                  exclusion or limitation of liability for consequential or incidental damages, so
                  the limitations above may not apply to you.
                </Paragraph>

                <ParagraphTitle>Governing Law</ParagraphTitle>

                <Paragraph mb={2}>
                  These Terms shall be governed and construed in accordance with the laws of the
                  Philippines, without regard to its conflict of law provisions.
                </Paragraph>
                <Paragraph mb={10}>
                  Our failure to enforce any right or provision of these Terms will not be
                  considered a waiver of those rights. If any provision of these Terms is held to be
                  invalid or unenforceable by a court, the remaining provisions of these Terms will
                  remain in effect. These Terms constitute the entire agreement between us regarding
                  our Service and supersede and replace any prior agreements we might have between
                  us regarding the Service.
                </Paragraph>

                <ParagraphTitle>Changes</ParagraphTitle>

                <Paragraph mb={2}>
                  We reserve the right, at our sole discretion, to modify or replace these Terms at
                  any time. If a revision is material, we will try to provide at least 30 days’
                  notice prior to any new terms taking effect. What constitutes a material change
                  will be determined at our sole discretion.
                </Paragraph>
                <Paragraph mb={10}>
                  By continuing to access or use our Service after those revisions become effective,
                  you agree to be bound by the revised terms. If you do not agree to the new terms,
                  please stop using the Service.
                </Paragraph>

                <ParagraphTitle>Contact Us</ParagraphTitle>

                <Paragraph mb={2} fontWeight="bold">
                  Address
                </Paragraph>
                <Paragraph mb={10}>
                  Fastech Manufacturing Complex, Ampere Street cor. West Road, Light Industry and
                  Science Park I, Cabuyao, Laguna, Philippines 4025
                </Paragraph>

                <Paragraph mb={2} fontWeight="bold">
                  Contact Numbers
                </Paragraph>
                <Element mb={2}>
                  <Element as="span" minWidth="120px" display="inline-flex">
                    Trunk line :
                  </Element>
                  <Element as="span" color="brand">
                    +63-49-543-0476
                  </Element>
                </Element>
                <Element mb={2}>
                  <Element as="span" minWidth="120px" display="inline-flex">
                    Manila line :
                  </Element>
                  <Element as="span" color="brand">
                    (+632) 8843-0017
                  </Element>
                </Element>
                <div>
                  <Element as="span" minWidth="120px" display="inline-flex">
                    Fax :
                  </Element>
                  <Element as="span" color="brand">
                    +63-49-543-0352
                  </Element>
                </div>
              </Col>
            </Row>
          </Container>
        </DefaultVector>
      </Section>
    </Site>
  )
}

const ParagraphTitle = styled.div`
  margin-bottom: 16px;
  text-transform: uppercase;
  color: ${theme.colors.brand};
  font-weight: ${theme.fontWeights.bold};
`

export default TermsAndConditions
